import Container from '@components/Container';
import Layout from '@components/Layout';
import LayoutTopSection from '@components/LayoutTopSection';
import Paragraph from '@components/Typography/Paragraph';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

import {
  emailAddress,
  phoneNumberPrzemek,
  phoneNumberMichal,
  gMapsLocalization,
  companyAddress,
  officeAddress,
} from '@utils/variables';
import { faEnvelope, faPhone, faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons';
import GMap from '../components/MyMapComponent';

const Contact = () => {
  const contact = 'Kontakt';

  const ALink = styled.a`
    display: block;
    margin: 0 0 0.5rem 0;
  `;

  const Awesome = styled(FontAwesomeIcon)`
    margin: 0 0.75rem 0 0;
  `;

  const CompanyInfoBox = styled.div`
    margin: 2rem 0 0 0;
  `;

  const Address = styled.address`
    font-style: normal;
  `;

  const renderContent = () => (
    <>
      <Paragraph mbottom={0.5}>Biuro:</Paragraph>
      <Address>
        <ALink target="_blank" rel="noopener noreferrer" href={`${gMapsLocalization}`}>
          <Awesome icon={faMapMarkedAlt} />
          {officeAddress}
        </ALink>
        <ALink href={`tel:${phoneNumberPrzemek}`}>
          <Awesome icon={faPhone} />
          {phoneNumberPrzemek}
        </ALink>
        <ALink href={`tel:${phoneNumberMichal}`}>
          <Awesome icon={faPhone} />
          {phoneNumberMichal}
        </ALink>
        <ALink href={`mailto:${emailAddress}`}>
          <Awesome icon={faEnvelope} />
          {emailAddress}
        </ALink>
      </Address>
      <CompanyInfoBox>
        <Paragraph mbottom={0.5}>Dane Spółki:</Paragraph>
        <Paragraph gray mbottom={0.5}>
          GBP Office sp. z o.o.
        </Paragraph>
        <Paragraph gray mbottom={0.5}>
          {companyAddress}
        </Paragraph>
        <Paragraph gray mbottom={0.5}>
          KRS: 0000763867
        </Paragraph>
        <Paragraph gray mbottom={0.5}>
          NIP: 8943136289
        </Paragraph>
        <Paragraph gray>REGON: 382119831</Paragraph>
      </CompanyInfoBox>
    </>
  );

  return (
    <Layout pageTitle={contact}>
      <Container>
        <LayoutTopSection
          title={contact}
          content={renderContent()}
          rightSide={<GMap />}
          showRightSideOnMobile
        />
      </Container>
    </Layout>
  );
};

export default Contact;
