import React, { memo } from 'react';
import { withGoogleMap, withScriptjs, GoogleMap, Marker } from 'react-google-maps';

const lat = 51.10483956318053;
const lng = 16.944869574495048;

const mapApiKey = 'AIzaSyCKorPMZpDgsvKq_An_m4vKUN8acX7FURw';
// AIzaSyDtyL_15AIvdY8x6iZaQYIRLfkA98wH-v8
// AIzaSyCKorPMZpDgsvKq_An_m4vKUN8acX7FURw

const mapStyles = [
  // { elementType: 'geometry', stylers: [{ color: '#2f2f2f' }] },
  // { elementType: 'labels.text.stroke', stylers: [{ color: '#2f2f2f' }] },
  // { elementType: 'labels.text.fill', stylers: [{ color: 'transparent' }] },
  // {
  //   featureType: 'administrative.locality',
  //   elementType: 'labels.text.fill',
  //   stylers: [{ color: '#ffffff', visibility: 'off' }],
  // },
  // {
  //   featureType: 'poi',
  //   elementType: 'labels.text.fill',
  //   stylers: [{ color: '#ffffff' }],
  // },
  // {
  //   featureType: 'poi.park',
  //   elementType: 'geometry',
  //   stylers: [{ color: 'transparent' }],
  // },
  // {
  //   featureType: 'poi.park',
  //   elementType: 'labels.text.fill',
  //   stylers: [{ visibility: 'off' }],
  // },
  // {
  //   featureType: 'road',
  //   elementType: 'geometry',
  //   stylers: [{ color: '#BD8604' }],
  // },
  // {
  //   featureType: 'road',
  //   elementType: 'geometry.stroke',
  //   stylers: [{ color: '#000000' }],
  // },
  // {
  //   featureType: 'road',
  //   elementType: 'labels.text.fill',
  //   stylers: [{ visibility: 'off' }],
  // },
  // {
  //   featureType: 'road.highway',
  //   elementType: 'geometry',
  //   stylers: [{ color: '#BD8604' }],
  // },
  // {
  //   featureType: 'road.highway',
  //   elementType: 'geometry.stroke',
  //   stylers: [{ color: '#2b2b2b' }],
  // },
  // {
  //   featureType: 'road.highway',
  //   elementType: 'labels.text.fill',
  //   stylers: [{ color: 'transparent' }],
  // },
  // {
  //   featureType: 'transit',
  //   elementType: 'geometry',
  //   stylers: [{ color: 'transparent' }],
  // },
  // {
  //   featureType: 'poi.business',
  //   stylers: [{ visibility: 'off' }],
  // },
  // {
  //   featureType: 'transit',
  //   elementType: 'labels.icon',
  //   stylers: [{ visibility: 'off' }],
  // },
  // {
  //   featureType: 'transit.station',
  //   elementType: 'labels.text.fill',
  //   stylers: [{ color: 'transparent' }],
  // },
  // {
  //   featureType: 'water',
  //   elementType: 'geometry',
  //   stylers: [{ color: 'transparent' }],
  // },
  // {
  //   featureType: 'water',
  //   elementType: 'labels.text.fill',
  //   stylers: [{ color: 'transparent' }],
  // },
  // {
  //   featureType: 'water',
  //   elementType: 'labels.text.stroke',
  //   stylers: [{ color: 'transparent' }],
  // },
  // {
  //   featureType: 'administrative.locality',
  //   elementType: 'labels',
  //   stylers: [{ visibility: 'off' }],
  // },
];

const MyMapComponent = withScriptjs(
  withGoogleMap(() => {
    return (
      <GoogleMap defaultZoom={16} defaultCenter={{ lat, lng }} options={{ styles: mapStyles }}>
        <Marker position={{ lat, lng }} />
      </GoogleMap>
    );
  })
);

const googleMapURL = `https://maps.googleapis.com/maps/api/js?key=${mapApiKey}&callback=initMap`;

const GMap = () => (
  <MyMapComponent
    isMarkerShown
    googleMapURL={googleMapURL}
    loadingElement={<div style={{ height: `100%` }} />}
    containerElement={<div style={{ height: `29.5rem` }} />}
    mapElement={<div style={{ height: `100%` }} />}
  />
);

MyMapComponent.propTypes = {};

MyMapComponent.defaultProps = {};

export default memo(GMap);
